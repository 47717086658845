import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const viewStyles = (theme) => ({
  console: {
    height: "30vh",
    backgroundColor: "#cccccc",
    overflowY: "scroll",
    overflowWrap: "break-word",
  },
  error: {
    color: "red",
    paddingLeft: "10px",
    margin: 0,
    whiteSpace: "pre-line",
    fontFamily: "monospace",
  },
  log: {
    color: "black",
    paddingLeft: "10px",
    margin: 0,
    whiteSpace: "pre-line",
    fontFamily: "monospace",
  },
  final: {
    color: "green",
    paddingLeft: "10px",
    margin: 0,
    whiteSpace: "pre-line",
    fontFamily: "monospace",
  },
});

class Console extends Component {
  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  };

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  splitText(text, className) {
    let array = [];
    text.split("\n").map((i, key) => {
      array.push(
        <p className={className} key={key}>
          {i}
        </p>
      );
    });

    return array;
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.console}>
        {this.props.logs.map((log) => {
          if (log.type == "error") {
            return this.splitText(JSON.stringify(log.msg), classes.error);
          } else if (log.type == "log") {
            return this.splitText(log.msg, classes.log);
          } else {
            return this.splitText(log.msg, classes.final);
          }
        })}
        <div
          style={{ float: "left", clear: "both" }}
          ref={(el) => {
            this.messagesEnd = el;
          }}
        ></div>
      </div>
    );
  }
}

Console.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(viewStyles)(Console);
