import { isAuthed } from '../tokenUtils'
import { saveToken } from '../tokenUtils'
import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { withStyles, Container, Grid } from '@material-ui/core'
import { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Redirect } from 'react-router-dom'
import Card from '@material-ui/core/Card'
import Divider from '@material-ui/core/Divider'

const useStyles = theme => ({
  root: {
    display: 'flex'
  },
  cover: {
    backgroundColor: '#000000c4',
    display: 'flex',
    alignItems: 'center',
    minWidth: '70%',
    justifyContent: 'space-around',
    padding: theme.spacing(2)
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto'
  },
  icon: {
    width: 200,
    height: 200
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  container: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    paddingBottom: theme.spacing(4)
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  mainBg: {
    display: 'flex',
    position: 'absolute',
    flexDirection: 'row',
    backgroundImage: `url(login_cover.jpg)`,
    height: '100%',
    zIndex: -1,
    width: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  }
})

class Signin extends Component {
  state = {
    isAuthed: false
  }
  componentDidMount() {
    google.accounts.id.initialize({
      client_id: this.props.clientID,
      callback: this.handleLogin,
      auto_select: false
    })
    google.accounts.id.renderButton(
      document.getElementById('btnGoogleSignIn'),
      { theme: 'outline', size: 'large' } // customization attributes
    )
    google.accounts.id.prompt() // also display the One Tap dialog
  }

  handleLogin = async response => {
    const res = await fetch('/api/v1/auth/google', {
      method: 'POST',
      body: JSON.stringify({
        token: response.credential
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    try {
      const data = await res.json()
      saveToken(data)
      this.setState({ isAuthed: true })
    } catch (error) {
      if (error.response) {
        const { data } = error.response
        this.setState({ signinError: data, loading: false, error: true })
      }
    }
  }

  render() {
    const { classes } = this.props
    if (isAuthed()) {
      return (
        <Redirect
          exact
          to='/'
        />
      )
    }

    return (
      <div className={classes.root}>
        <CssBaseline />
        <main
          className={classes.content}
          color='background'
        >
          <div className={classes.mainBg} />

          <Container
            maxWidth='lg'
            className={classes.container}
          >
            <Grid
              className={classes.loginContainer}
              container
              spacing={6}
            >
              <Card
                elevation={10}
                className={classes.cover}
              >
                <div className={classes.info}>
                  <img
                    className={classes.icon}
                    alt=''
                    src='icon.png'
                  ></img>
                </div>
                <Divider
                  light
                  orientation='vertical'
                  flexItem
                />
                <div id='btnGoogleSignIn'></div>
              </Card>
            </Grid>
          </Container>
        </main>
      </div>
    )
  }
}

Signin.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(useStyles)(Signin)
