import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { isAuthed } from '../tokenUtils'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      return isAuthed() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login'
          }}
        />
      )
    }}
  />
)

export default PrivateRoute
