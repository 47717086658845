import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import { HashRouter } from 'react-router-dom'
import axios from 'axios'

import PrivateRoute from './PrivateRoute'
import Home from './Home'
import Signin from './Signin'
import AuthNavbar from './AuthNavbar'
import { saveAppHeader, saveServerRepoName } from '../tokenUtils'
class AppContainer extends Component {
  state = {
    clientID: ''
  }

  componentDidMount() {
    axios
      .get('/env')
      .then(resp => resp.data)
      .then(data => {
        this.setState({ clientID: data.clientID })
        document.title = data.appHeader
        saveAppHeader(data.appHeader)
        saveServerRepoName(data.serverRepoName)
      })
      .catch(err => {
        console.error('Error fetching data:', err)
      })
  }

  render() {
    if (this.state.clientID == '') {
      return <div />
    }
    return (
      <HashRouter>
        <div style={styles.container}>
          <PrivateRoute
            path='/'
            component={AuthNavbar}
          />
          <Route
            exact
            path='/login'
            render={props => (
              <Signin
                {...props}
                clientID={this.state.clientID}
              />
            )}
          />
          <PrivateRoute
            path='/'
            component={Home}
          />
        </div>
      </HashRouter>
    )
  }
}

const styles = {
  container: {
    position: 'relative',
    margin: '0 auto'
  }
}

export default AppContainer
