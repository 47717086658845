import React, { Component } from 'react'
import { removeToken } from '../tokenUtils'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { green } from '@material-ui/core/colors'
import CssBaseline from '@material-ui/core/CssBaseline'
import PropTypes from 'prop-types'

import { getAppHeader } from '../tokenUtils'

const drawerWidth = 240

const useStyles = theme => ({
  root: {
    flexGrow: 1,
    display: 'flex'
  },
  title: {
    flexGrow: 1
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    zIndex: 1000,
    backgroundColor: green[400]
  }
})

class AuthNavBar extends Component {
  render() {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position='fixed'
          sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
          className={classes.appBar}
        >
          <Toolbar>
            <Typography
              variant='h6'
              className={classes.title}
            >
              {getAppHeader()}
            </Typography>
            <Button
              onClick={() => {
                removeToken()
                this.props.history.push('/login')
              }}
              color='inherit'
            >
              Logout
            </Button>
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}

AuthNavBar.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(useStyles)(AuthNavBar)
