export const getToken = () => localStorage.getItem('jwt-token')

export const saveToken = token => localStorage.setItem('jwt-token', token)

export const removeToken = () => {
  localStorage.removeItem('jwt-token')
  google.accounts.id.disableAutoSelect()
}

export const getTokenInfo = () => {
  try {
    const token = getToken()
    if (token) {
      return token
    }
  } catch (error) {
    console.error(error)
  }
}

export const isAuthed = () => {
  const token_info = getTokenInfo()
  if (token_info && token_info.endsWith('gameberrylabs.com')) {
    return true
  }
  return false
}

export const saveAppHeader = appHeader => localStorage.setItem('appHeader', appHeader)
export const getAppHeader = () => localStorage.getItem('appHeader')

export const saveServerRepoName = serverRepoName => localStorage.setItem('serverRepoName', serverRepoName)
export const getServerRepoName = () => localStorage.getItem('serverRepoName')
